<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        <i>Precision</i> means that <b>&nbsp;[A]&nbsp;</b> measurements are close to
        <b>&nbsp;[B].</b>
      </p>

      <p class="mb-3">
        <i>Accuracy</i> means that <b>&nbsp;[C]&nbsp;</b> measurements are close to
        <b>&nbsp;[D].</b>
      </p>

      <p class="mb-2">
        The following data was collected by three students who measured the density of ethanol
        having done 4 trials each.
      </p>

      <v-simple-table class="pl-6" style="max-width: 560px">
        <thead>
          <tr>
            <td style="font-weight: bold"></td>
            <td style="font-weight: bold">Brian</td>
            <td style="font-weight: bold">Tiffany</td>
            <td style="font-weight: bold">Sung</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Densities (g/mL)</td>
            <td>0.770, 0.791, 0.780, 0.795</td>
            <td>0.796, 0.798, 0.799, 0.797</td>
            <td>0.788, 0.790, 0.787, 0.789</td>
          </tr>
          <tr>
            <td>Average (g/mL)</td>
            <td>0.784</td>
            <td>0.798</td>
            <td>0.789</td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-3 mt-7">Brian's data is <b>&nbsp;[E].</b></p>

      <p class="mb-3">Tiffany's data is <b>&nbsp;[F].</b></p>

      <p class="mb-7">Sung's data is <b>&nbsp;[G].</b></p>

      <v-select
        v-model="inputs.labelA"
        :items="itemsAC"
        item-text="text"
        item-value="value"
        label="[A]: "
        class="mb-1"
        style="width: 350px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.labelB"
        :items="itemsBD"
        item-text="text"
        item-value="value"
        label="[B]: "
        class="mb-1"
        style="width: 350px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.labelC"
        :items="itemsAC"
        item-text="text"
        item-value="value"
        label="[C]: "
        class="mb-1"
        style="width: 350px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.labelD"
        :items="itemsBD"
        item-text="text"
        item-value="value"
        label="[D]: "
        class="mb-1"
        style="width: 350px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.labelE"
        :items="itemsEFG"
        item-text="text"
        item-value="value"
        label="[E]: "
        class="mb-1"
        style="width: 350px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.labelF"
        :items="itemsEFG"
        item-text="text"
        item-value="value"
        label="[F]: "
        class="mb-1"
        style="width: 350px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.labelG"
        :items="itemsEFG"
        item-text="text"
        item-value="value"
        label="[G]: "
        class="mb-1"
        style="width: 350px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'Chem1LD_LFQ_Question6',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        labelA: null,
        labelB: null,
        labelC: null,
        labelD: null,
        labelE: null,
        labelF: null,
        labelG: null,
      },
      itemsAC: [
        {text: 'individual', value: 'one'},
        {text: 'a few', value: 'few'},
        {text: 'several', value: 'several'},
        {text: 'the average value of', value: 'average'},
        {text: 'all', value: 'all'},
      ],
      itemsBD: [
        {text: 'the accepted value', value: 'accepted'},
        {text: 'the average value', value: 'average'},
        {text: 'each other', value: 'eachOther'},
        {text: 'zero', value: 'zero'},
      ],
      itemsEFG: [
        {text: 'accurate', value: 'accurate'},
        {text: 'precise', value: 'precise'},
        {text: 'accurate and precise', value: 'accurateAndPrecise'},
        {text: 'none of these', value: 'none'},
      ],
    };
  },
};
</script>
<style scoped></style>
