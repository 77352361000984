var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_c('i',[_vm._v("Precision")]),_vm._v(" means that "),_c('b',[_vm._v(" [A] ")]),_vm._v(" measurements are close to "),_c('b',[_vm._v(" [B].")])]),_c('p',{staticClass:"mb-3"},[_c('i',[_vm._v("Accuracy")]),_vm._v(" means that "),_c('b',[_vm._v(" [C] ")]),_vm._v(" measurements are close to "),_c('b',[_vm._v(" [D].")])]),_c('p',{staticClass:"mb-2"},[_vm._v(" The following data was collected by three students who measured the density of ethanol having done 4 trials each. ")]),_c('v-simple-table',{staticClass:"pl-6",staticStyle:{"max-width":"560px"}},[_c('thead',[_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}}),_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Brian")]),_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Tiffany")]),_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Sung")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Densities (g/mL)")]),_c('td',[_vm._v("0.770, 0.791, 0.780, 0.795")]),_c('td',[_vm._v("0.796, 0.798, 0.799, 0.797")]),_c('td',[_vm._v("0.788, 0.790, 0.787, 0.789")])]),_c('tr',[_c('td',[_vm._v("Average (g/mL)")]),_c('td',[_vm._v("0.784")]),_c('td',[_vm._v("0.798")]),_c('td',[_vm._v("0.789")])])])]),_c('p',{staticClass:"mb-3 mt-7"},[_vm._v("Brian's data is "),_c('b',[_vm._v(" [E].")])]),_c('p',{staticClass:"mb-3"},[_vm._v("Tiffany's data is "),_c('b',[_vm._v(" [F].")])]),_c('p',{staticClass:"mb-7"},[_vm._v("Sung's data is "),_c('b',[_vm._v(" [G].")])]),_c('v-select',{staticClass:"mb-1",staticStyle:{"width":"350px"},attrs:{"items":_vm.itemsAC,"item-text":"text","item-value":"value","label":"[A]: "},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.labelA),callback:function ($$v) {_vm.$set(_vm.inputs, "labelA", $$v)},expression:"inputs.labelA"}}),_c('v-select',{staticClass:"mb-1",staticStyle:{"width":"350px"},attrs:{"items":_vm.itemsBD,"item-text":"text","item-value":"value","label":"[B]: "},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.labelB),callback:function ($$v) {_vm.$set(_vm.inputs, "labelB", $$v)},expression:"inputs.labelB"}}),_c('v-select',{staticClass:"mb-1",staticStyle:{"width":"350px"},attrs:{"items":_vm.itemsAC,"item-text":"text","item-value":"value","label":"[C]: "},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.labelC),callback:function ($$v) {_vm.$set(_vm.inputs, "labelC", $$v)},expression:"inputs.labelC"}}),_c('v-select',{staticClass:"mb-1",staticStyle:{"width":"350px"},attrs:{"items":_vm.itemsBD,"item-text":"text","item-value":"value","label":"[D]: "},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.labelD),callback:function ($$v) {_vm.$set(_vm.inputs, "labelD", $$v)},expression:"inputs.labelD"}}),_c('v-select',{staticClass:"mb-1",staticStyle:{"width":"350px"},attrs:{"items":_vm.itemsEFG,"item-text":"text","item-value":"value","label":"[E]: "},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.labelE),callback:function ($$v) {_vm.$set(_vm.inputs, "labelE", $$v)},expression:"inputs.labelE"}}),_c('v-select',{staticClass:"mb-1",staticStyle:{"width":"350px"},attrs:{"items":_vm.itemsEFG,"item-text":"text","item-value":"value","label":"[F]: "},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.labelF),callback:function ($$v) {_vm.$set(_vm.inputs, "labelF", $$v)},expression:"inputs.labelF"}}),_c('v-select',{staticClass:"mb-1",staticStyle:{"width":"350px"},attrs:{"items":_vm.itemsEFG,"item-text":"text","item-value":"value","label":"[G]: "},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.labelG),callback:function ($$v) {_vm.$set(_vm.inputs, "labelG", $$v)},expression:"inputs.labelG"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }